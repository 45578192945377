<template>
    <AutocompleteBase
        v-model="selectedLocation"
        :items="displayedLocations"
        :outlined="outlined"
        :itemText="(item) => getName(item)"
        :aria-required="required"
        placeholder="Location"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';

// Components
import AutocompleteBase from '@/components/base/dropdowns/AutocompleteBase';

/**
 * @emits 'input' => { value }
 */
export default {
    name: 'DropdownLocationList',
    components: {
        AutocompleteBase,
    },
    props: {
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
        prependAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Object,
            required: false,
            default: () => ( {} ),
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            FormatUtil,
            isOpen: false,
        };
    },
    computed: {
        ...mapState( {
            locationList: ( state ) => state.GlobalResources.locationList,
        } ),
        /** @returns { import('@/typedefs/location').location[] } */
        displayedLocations() {
            const displayOptions = [ ...this.locationList ];
            if ( this.prependAll ) {
                displayOptions.unshift( { id: 0, name: '' } );
            }
            return displayOptions;
        },
        selectedLocation: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val ?? {} );
            },
        },
    },
    mounted() {
        if ( this.prependAll && ObjectUtil.isEmpty( this.value ) ) {
            const [ selectedLocation ] = this.displayedLocations;
            this.selectedLocation = selectedLocation;
        }
    },
    methods: {
        emitSelection() {
            this.$emit( 'selection' );
        },
        getName( item ) {
            return item.id === 0 && this.prependAll ? 'All' : FormatUtil.locationNameToPrettyName( item.name );
        },
    },
};
</script>
