<template>
    <span class="asterisk">*</span>
</template>

<script>
export default {
    name: 'RequiredAsterisk',
};
</script>
<style lang="scss" scoped>
.asterisk {
    margin-right: .25rem;
    color: var(--v-error-base);
    font-weight: bolder;
}
</style>
