<template>
    <div class="custom-container" :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]">

        <TheSidebar/>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :successCardDuration="10000"
        />

        <section class="main">
            <div id="po-information" class="purchase-order-create">
                <div class="purchase-order-create__section">
                    <label
                        for="location"
                        class="purchase-order-create__section__label"
                    >
                        <RequiredAsterisk />
                        Owner Location
                    </label>
                    <div class="purchase-order-create__section__input">
                        <DropdownLocationList
                            id="location"
                            v-model="location"
                            outlined
                            required
                        />
                    </div>
                </div>

                <div class="purchase-order-create__section">
                    <label
                        for="supplier"
                        class="purchase-order-create__section__label"
                    >
                        <RequiredAsterisk />
                        Supplier
                    </label>
                    <div  class="purchase-order-create__section__input">
                        <DropdownPrimarySupplier
                            id="supplier"
                            v-model="supplier"
                            :hideDetails="true"
                            required
                        />
                    </div>
                </div>

                <div class="purchase-order-create__section">
                    <label
                        for="date"
                        class="purchase-order-create__section__label"
                    >
                        <RequiredAsterisk />
                        Expected Receive Date
                    </label>
                    <div class="purchase-order-create__section__input">
                        <InputDatePicker
                            label="Expected Receive Date"
                            :date.sync="expectedReceiveDate"
                            outlined
                            :additional-rules="[ value => !!value ]"
                        />
                    </div>
                </div>

                <div class="purchase-order-create__section">
                    <label
                        for="prefix-override"
                        class="purchase-order-create__section__label"
                    >
                        Override PO Number Prefix
                    </label>
                    <div class="purchase-order-create__section__input">
                        <v-text-field
                            id="prefix-override"
                            v-model="prefixOverride"
                            placeholder="Default is 'PO'"
                            dense
                            hideDetails
                            outlined
                        />
                    </div>
                </div>
                <div class="purchase-order-create__section purchase-order-create__section--note">
                    <!-- Notes -->
                    <label
                        for="description"
                        class="purchase-order-create__section__label"
                    >
                        Notes
                    </label>
                    <div class="purchase-order-create__section__textarea">
                        <v-textarea
                            id="description"
                            v-model="description"
                            dense
                            hideDetails
                            outlined
                        />
                    </div>

                </div>
                <!-- Create Button -->
                <div>
                    <v-btn
                        color="primary"
                        :disabled="inFlight || !hasRequiredInformation"
                        @click="createPurchaseOrder()"
                    >
                        Create PO
                    </v-btn>
                    <v-btn
                        color="primary"
                        target="_blank"
                        class="ml-2"
                        :disabled="!createdPurchaseOrderNumber"
                        :to="{ name: 'PurchaseOrderSearch', query: { docNum: createdPurchaseOrderNumber }, target: '_blank'}"
                    >
                        View PO {{ createdPurchaseOrderNumber }}
                    </v-btn>
                </div>
            </div>

            <ThePageFooterBar/>
        </section>

    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import RequiredAsterisk from '@/components/base/RequiredAsterisk';

import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import InputDatePicker from '@/components/InputDatePicker';

export default {
    name: 'PurchaseOrderCreate',
    components: {
        InputDatePicker,
        ThePageHeadingBar,
        TheSidebar,
        ThePageFooterBar,
        RequiredAsterisk,
        DropdownLocationList,
        DropdownPrimarySupplier,
    },
    data() {
        return {
            fullSidebar: false,
            pageHeading: 'Purchase Order Manual Create',
            inFlight: false,

            location: {},
            supplier: {},
            expectedReceiveDate: '',
            prefixOverride: '',
            description: '',
        };
    },
    computed: {
        ...mapGetters( {
            hasLocationList: 'GlobalResources/hasLocationList',
        } ),
        ...mapState( {
            createdPurchaseOrderNumber: ( state ) => state.PurchaseOrder.createdPurchaseOrderNumber,
        } ),

        locationIsEmpty() {
            return ObjectUtil.isEmpty( this.location );
        },
        supplierIsEmpty() {
            return ObjectUtil.isEmpty( this.supplier );
        },
        expectedReceiveDateIsEmpty() {
            return this.expectedReceiveDate === '';
        },
        hasRequiredInformation() {
            return !this.locationIsEmpty
                && !this.supplierIsEmpty
                && !this.expectedReceiveDateIsEmpty;
        },
    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        async createPurchaseOrder() {
            this.inFlight = true;
            try {
                await this.$store.dispatch( 'PurchaseOrder/create', {
                    location: this.location,
                    supplier: this.supplier,
                    expectedReceiveDate: this.expectedReceiveDate,
                    prefixOverride: this.prefixOverride,
                    description: this.description,
                } );
            } catch ( error ) {
                console.log( 'Error in createPurchaseOrder: ', error );
            } finally {
                this.inFlight = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .purchase-order-create__section {
        margin: 1rem 0;
        &__input {
            max-width: 300px;
        }

        &__label {
            display: flex;
            align-items: center;
        }

        &__textarea {
            max-width: 600px;
        }
    }
</style>
